import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPermissionGranted from './permission-granted.reducer';
import { UserType, User_Permission } from 'src/app/shared/models';

export const selectPermissionsGrantedState =
  createFeatureSelector<fromPermissionGranted.PermissionGrantedState>(
    fromPermissionGranted.permissionGrantedFeatureKey
  );

export const selectPermissionsGranted = createSelector(
  selectPermissionsGrantedState,
  state => state
);

export const selectPermissionsGrantedByUserId = (userId: string) => createSelector(
  selectPermissionsGrantedState,
  state => ({
    ...state,
    permissionGranted: state.permissionsGranted.find(x => x.user?.id === userId),
  })
);

export const selectPermissionsGrantedByGrantedId = (
  grantedId: string,
  userId?: string
) =>
  createSelector(selectPermissionsGrantedState, state => ({
    ...state,
    permissionsGranted: state.permissionsGranted
      .filter(
        permissionGranted => permissionGranted.grantedUserId === grantedId
      )
      .map(permissionGranted => {
        console.log('permissionGranted', permissionGranted, userId);
        return {
          ...permissionGranted,
          userClaimedPermission: state.permissionsGranted.find(
            x =>
              userId &&
              x.grantedUserId === userId &&
              x.user?.id === permissionGranted.user?.id
          ),
        } as User_Permission;
      }),
  }));

export const selectPermissionsGrantedWithoutInvitedByGrantedId = (
  grantedId: string
) =>
  createSelector(selectPermissionsGrantedState, state => ({
    ...state,
    permissionsGranted: state.permissionsGranted.filter(
      permissionGranted =>
        permissionGranted.grantedUserId === grantedId &&
        !permissionGranted.invitedAt
    ),
  }));

export const selectPermissionsGrantedWithoutInvited = createSelector(
  selectPermissionsGrantedState,
  state => ({
    ...state,
    permissionsGranted: state.permissionsGranted.filter(
      permissionGranted => !permissionGranted.invitedAt
    ),
  })
);

export const selectPermissionsGrantedWithTypeConsumer = createSelector(
  selectPermissionsGrantedState,
  state => ({
    ...state,
    permissionsGranted: state.permissionsGranted.filter(
      permissionGranted =>
        permissionGranted?.user?.userType === UserType.CONSUMER
    ),
  })
);

export const selectPermissionsGrantedInvitations = createSelector(
  selectPermissionsGrantedState,
  state => ({
    ...state,
    permissionsGranted: state.permissionsGranted.filter(
      permissionGranted => permissionGranted.invitedAt
    ),
  })
);

export const selectPermissionsGrantedToImpersonateByGrantedId = (
  grantedId: string,
  type?: 'top' | 'sub'
) =>
  createSelector(selectPermissionsGrantedState, state => ({
    ...state,
    permissionsGranted: state.permissionsGranted
      .filter(permissionGranted => {
        let allowed =
          permissionGranted.grantedUserId === grantedId &&
          !permissionGranted.invitedAt &&
          permissionGranted.user?.canImpersonate;

        if (type === 'top') {
          allowed = allowed && !permissionGranted.hasAccessFromParent;
        } else if (type === 'sub') {
          allowed = allowed && permissionGranted.hasAccessFromParent;
        }

        return allowed;
      })
      .sort((a, b) => {
        const aName = a.user?.name || a.user?.firstName || '';
        const bName = b.user?.name || b.user?.firstName || '';

        if (aName < bName) {
          return -1;
        }
        if (aName > bName) {
          return 1;
        }
        return 0;
      }),
  }));

export const selectPermissionsGrantedToImpersonate = createSelector(
  selectPermissionsGrantedState,
  state => ({
    ...state,
    permissionsGranted: state.permissionsGranted.filter(
      permissionGranted =>
        !permissionGranted.invitedAt && permissionGranted.user?.canImpersonate
    ),
  })
);
